"use strict";

module.exports = function (r1, r2) {
  var a = r2.left > r1.right,
    b = r2.right < r1.left,
    c = r2.top > r1.bottom,
    d = r2.bottom < r1.top,
    e = ( a || b || c || d),
    f = !e;
  return f;
};