/**
 helper for lazy loading stuff
 */
"use strict";


 var contains = require('./contains.js');
//import contains from "./contains";

//

/**
 * takes a list of dom elements and messes with classes if they are within a specific container
 * @param lazyClasses
 */
var LazyAllTheThings = function($, container, lazyClasses) {

  this.lazyClasses = lazyClasses || ['.lazy-src', '.lazy-style', '.lazy-video'];
  this.lazyElements = lazyClasses.reduce(function(acc, selector) {//grab the elements
    return acc.concat(Array.prototype.slice.call(document.querySelectorAll(selector)));
  }, []);

  this.$container = $(container);
  this.$ = $;

  this.update();
};

LazyAllTheThings.prototype.update = function ()
{
  if (this.lazyElements.length > 0) {

    var rect = {left:0, top: 0, right: this.$container.outerWidth(), bottom: this.$container.outerHeight()};

    this.lazyElements.filter(function (element) {
      //filter out visible items only
      var er = element.getBoundingClientRect();
      return !(er.top == 0 && er.bottom == 0 && er.left == 0 && er.right == 0) && contains(rect, er);
    }).forEach(function (element) {

      var e = element, $e = this.$(e);

      // loop over visible items and check supported classes and apply appropriate function
      this.lazyClasses.forEach(function (className) {
        var srcAttr = className.replace(/\.lazy/,'data'),
          destAttr = className.replace(/\.lazy\-/,'');

        if ($e.is(className)) {

          //special case for
          $e.removeClass(className.replace(/^\./,''));

          if (className == '.lazy-video' && e.firstChild && !e.firstChild.hasAttribute('src')) {
            e.firstChild.setAttribute('src', e.firstChild.getAttribute('data-src'));
            e.load();
          } else if (!e.hasAttribute(destAttr)) {
            e.setAttribute(destAttr, e.getAttribute(srcAttr));
            e.removeAttribute(srcAttr);
          }


        }

      }.bind(this));
    }.bind(this));


  }
};

module.exports = LazyAllTheThings;